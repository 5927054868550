import React, { useState } from "react";
import contact from "../assets/contact.svg";
import "./Contato.css";

export default function Contato() {
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const verificarInputs = () => {
    const nomeInput = document.getElementById("nome");
    const telefoneInput = document.getElementById("telefone");
    const emailInput = document.getElementById("email");
    const mensagemInput = document.getElementById("mensagem");

    let nomeLabel = document.getElementById("nomeLabel");
    let telefoneLabel = document.getElementById("telefoneLabel");
    let emailLabel = document.getElementById("emailLabel");
    let mensagemLabel = document.getElementById("mensagemLabel");

    if (nomeInput.value.trim() === "") {
      nomeInput.classList.add("border-solid", "border-2", "border-red-500");
      if (!nomeLabel) {
        nomeLabel = document.createElement("label");
        nomeLabel.setAttribute("id", "nomeLabel");
        nomeLabel.setAttribute("for", "nome");
        nomeLabel.classList.add("text-red-500", "block", "mb-2");
        nomeLabel.innerText = "Campo Obrigatório*";
        nomeInput.parentNode.insertBefore(nomeLabel, nomeInput);
      }
    } else {
      nomeInput.classList.remove("border-solid", "border-2", "border-red-500");
      if (nomeLabel) nomeLabel.remove();
    }

    if (telefoneInput.value.trim() === "") {
      telefoneInput.classList.add("border-solid", "border-2", "border-red-500");
      if (!telefoneLabel) {
        telefoneLabel = document.createElement("label");
        telefoneLabel.setAttribute("id", "telefoneLabel");
        telefoneLabel.setAttribute("for", "telefone");
        telefoneLabel.classList.add("text-red-500", "block", "mb-2");
        telefoneLabel.innerText = "Campo Obrigatório*";
        telefoneInput.parentNode.insertBefore(telefoneLabel, telefoneInput);
      }
    } else {
      telefoneInput.classList.remove(
        "border-solid",
        "border-2",
        "border-red-500"
      );
      if (telefoneLabel) telefoneLabel.remove();
    }

    if (emailInput.value.trim() === "") {
      emailInput.classList.add("border-solid", "border-2", "border-red-500");
      if (!emailLabel) {
        emailLabel = document.createElement("label");
        emailLabel.setAttribute("id", "emailLabel");
        emailLabel.setAttribute("for", "email");
        emailLabel.classList.add("text-red-500", "block", "mb-2");
        emailLabel.innerText = "Campo Obrigatório*";
        emailInput.parentNode.insertBefore(emailLabel, emailInput);
      }
    } else {
      emailInput.classList.remove("border-solid", "border-2", "border-red-500");
      if (emailLabel) emailLabel.remove();
    }

    if (mensagemInput.value.trim() === "") {
      mensagemInput.classList.add("border-solid", "border-2", "border-red-500");
      if (!mensagemLabel) {
        mensagemLabel = document.createElement("label");
        mensagemLabel.setAttribute("id", "mensagemLabel");
        mensagemLabel.setAttribute("for", "mensagem");
        mensagemLabel.classList.add("text-red-500", "block", "mb-2");
        mensagemLabel.innerText = "Campo Obrigatório*";
        mensagemInput.parentNode.insertBefore(mensagemLabel, mensagemInput);
      }
    } else {
      mensagemInput.classList.remove(
        "border-solid",
        "border-2",
        "border-red-500"
      );
      if (mensagemLabel) mensagemLabel.remove();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    verificarInputs();
    const form = e.target;
    const formData = new FormData(form);

    try {
      const response = await fetch("envio.php", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        setModalMessage(
          "Dados enviados com sucesso. Em breve, um representante entrará em contato com você."
        );
      } else {
        console.log(Error);
        setModalMessage("Erro, tente novamente");
      }
    } catch (error) {
      console.error(Error);
      setModalMessage("Erro, tente novamente");
    } finally {
      setModalVisible(true);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <>
    <div className="backgroundCont">
      <div>
        <h1 className="text-center text-4xl  text-gradientTitle pt-24 pb-16 fontBebasNeue">
          ENVIE SUGESTÕES, TIRE DÚVIDAS OU INICIE SEU TESTE AGORA MESMO.
        </h1>
        <div className="flex items-center justify-center  md:gap-20 ">
          <form onSubmit={handleSubmit} className="flex flex-col items-start">
            <input
              className="shadowInput border-solid border-2 border-slate-100 my-1 py-2 px-4 rounded-full w-80 md:w-full"
              type="text"
              id="nome"
              placeholder="Seu nome"
              name="nome"
              value={nome}
              onChange={(e) => {
                setNome(e.target.value);
              }}
            />
            <input
              className="shadowInput my-1 py-2 border-solid border-2 border-slate-100 px-4 rounded-full w-80 md:w-full "
              type="number"
              id="telefone"
              name="telefone"
              placeholder="Seu telefone"
              value={telefone}
              onChange={(e) => {
                setTelefone(e.target.value);
              }}
            />
            <input
              className="shadowInput my-1 py-2 border-solid border-2 border-slate-100 px-4 rounded-full w-80 md:w-full"
              type="email"
              id="email"
              name="email"
              placeholder="Seu e-mail"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <textarea
              className="h-28 shadowInput my-2 py-5 rounded-3xl border-solid border-2 border-slate-100 w-80 md:w-96 custom-placeholder-textarea overflow-hidden"
              type="text"
              id="mensagem"
              name="mensagem"
              placeholder="Sua mensagem"
              value={mensagem}
              onChange={(e) => {
                setMensagem(e.target.value);
              }}
            />
            <button
              className="py-2 px-4 text-white gradientButton mt-6 mb-36  rounded-full font-bold"
              type="submit"
            >
              Enviar
            </button>
          </form>
          <div className="hidden md:block mb-52">
            <img src={contact} alt="" />
          </div>
        </div>
      </div>

      {modalVisible && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <p className="font-bold">{modalMessage}</p>
          </div>
        </div>
      )}
      </div>
    </>
  );
}
