import React, { useEffect, useRef, useState } from 'react'
import "./Migracao.css"
import Setas from "../assets/Setas.svg"
import macMigr from "../assets/macMigracao.svg"
import macReto from "../assets/macRetoMig.svg"
import etapas from "../assets/etapasMigra.svg"
import feedbacksMig from "../assets/falamSobreNos.svg"
import magic from "../assets/magicpen.svg"
import lock from "../assets/lock.svg"
import cubo from "../assets/convert-3d-cube.svg"
import etapasResponsiva from "../assets/etapasResponsiva.svg"
import feedbacksMigResponsivo from "../assets/feedbacksResponsivosMigra.svg"

const Migracao = () => {
    const [showForm, setShowForm] = useState(false);
    const formRef = useRef(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js";
        script.onload = () => {
            if (window.RDStationForms) {
                const formContainer = document.getElementById('novo-lead-site-18d99087cd5f9a0faba1');
                if (formContainer) {
                    new window.RDStationForms('novo-lead-site-18d99087cd5f9a0faba1', null).createForm();
                }
            }
        };
        document.body.appendChild(script);
    }, [showForm]);

    const handleButtonClick = () => {
        setShowForm(!showForm);
    };
    const handleClickOutside = (event) => {
        if (formRef.current && !formRef.current.contains(event.target)) {
            setShowForm(false);
        }
    };

    useEffect(() => {
        if (showForm) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showForm]);


    return (
        <>
            {showForm && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div ref={formRef} className=" z-10 rounded-lg shadow-lg">
                        <div role="main" id="novo-lead-site-18d99087cd5f9a0faba1"></div>
                    </div>
                </div>
            )}
            <div className='backgroundMigracao'>
                <div className="flex items-center text-center justify-center w-full ">
                    <div className="md:ml-24 p-6">
                        <h3 className="font-medium text-sm md:text-left md:text-xl mb-3">
                            De um salto com a migração trazendo
                        </h3>
                        <h1 className="text-4xl md:text-6xl md:text-left titlePlataforma mx-4 md:mx-0 fontBebasNeue ">
                            SUA CENTRAL PARA A MELHOR PLATAFORMA DO MERCADO
                        </h1>
                        <div className="flex justify-center md:justify-normal w-full md:w-4/12 mt-4">
                            <img className="w-5/12 md:w-8/12" src={Setas} alt="" />
                        </div>
                    </div>
                    <div className=" hidden md:flex justify-center w-full">
                        <img className="w-3/6" src={macMigr} alt="MacBook" />
                    </div>
                </div >
                <div>
                    <h1 className="pt-16 font-bold text-2xl md:text-4xl text-gradient text-center md:mb-6">
                        POR QUE MIGRAR?
                    </h1>
                    <div className="z-0  flex flex-col md:flex-row justify-center items-center md:gap-20">
                        <div className="my-5 md:my-20 bg-white p-2 md:p-10 shadow-2xl h-56 md:h-72  w-64 flex flex-col justify-center items-center gap-5 rounded-xl">
                            <img className="mt-2 w-8" src={magic} alt="" />
                            <div className="bg-blue-600  h-00 rounded-lg w-20"> </div>
                            <div className="titlePlataforma font-bold text-lg md:text-2xl text-center">
                                <p>Soluções</p>
                                <span>personalizadas </span>
                                <span>e escaláveis</span>
                            </div>
                        </div>
                        <div className="my-10 md:my-20 bg-white p-2  md:p-10 shadow-2xl h-56 md:h-72  w-64 flex flex-col justify-center items-center gap-5 rounded-xl">
                            <img className="mt-2 w-10" src={lock} alt="" />
                            <div className="bg-blue-600  h-00 rounded-lg w-20 "> </div>
                            <div className="titlePlataforma w-10/12 font-bold text-lg md:text-2xl text-center">
                                <p>Tecnologia de </p>
                                <span>ponta com alta </span>
                                <span>precisão</span>
                            </div>
                        </div>
                        <div className="my-10 md:my-20 bg-white p-2 md:p-10 shadow-2xl h-56 md:h-72  w-64 flex flex-col justify-center items-center gap-5 rounded-xl">
                            <img className="mt-2 w-12" src={cubo} alt="" />
                            <div className="bg-blue-600  h-00 rounded-lg w-20"> </div>
                            <div className="titlePlataforma w-10/12 font-bold text-lg md:text-2xl text-center">
                                <p>Integração </p>
                                <span>com sistemas </span>
                                <span>existentes</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center items-center md:my-40 gap-10'>
                    <div className="hidden md:ml-40 md:block md:w-6/12 ">
                        <img src={macReto} className="w-full" />
                    </div>
                    <div className='flex flex-col items-center md:items-start'>
                        <h1 className="flex justify-center md:justify-start md:pt-16 font-bold text-2xl md:text-3xl text-gradient mb-2 md:mb-6">
                            COMO FUNCIONA?
                        </h1>
                        <span className='flex justify-center md:justify-start text-lg w-8/12 text-center md:text-left'>
                            A equipe de especialistas da Selsyn Tecnologia avalia cuidadosamente o ambiente da sua operação para definir o plano ideal de migração da sua base de dados. O processo é conduzido com eficiência e segurança, garantindo que sua rotina operacional continue sem interrupções.
                        </span>
                        <button
                            onClick={handleButtonClick}
                            className="text-gradientButton md:text-gradientButton my-6 px-2 font-semibold w-56 py-3 rounded-full"
                        >
                            QUERO SABER MAIS
                        </button>
                    </div>

                </div>
            </div>

            <div className='bgMigracaoEtap p-4'>
                <h1 className="pt-16 font-bold text-2xl md:text-4xl text-white text-center mb-5 md:mb-10">
                    ETAPAS DO PROCESSO
                </h1>
                <div className="hidden mx-auto md:block w-8/12 ">
                    <img src={etapas} className="w-full" />
                </div>
                <div className="block mx-auto md:hidden w-11/12 ">
                    <img src={etapasResponsiva} className="w-full" />
                </div>
            </div>
            <div className='flex justify-center flex-col md:py-9 backgroundMigracao'>
                <h1 className="pt-10 md:pt-16 font-bold text-2xl md:text-4xl text-gradient text-center mb-2">
                    O QUE FALAM SOBRE NÓS?
                </h1>
                <div className="hidden mx-auto md:block md:w-9/12 my-10">
                    <img src={feedbacksMig} className="w-full" />
                </div>
                <div className="block mx-4 md:hidden md:w-9/12 my-4">
                    <img src={feedbacksMigResponsivo} className="w-full" />
                </div>
                <button
                    onClick={handleButtonClick}
                    className="text-gradientButton md:text-gradientButton mx-auto mb-10 md:mb-0 mt-6 px-2 font-semibold w-56 py-3 rounded-full "
                >
                    QUERO MIGRAR
                </button>
            </div>

        </>
    )
}

export default Migracao