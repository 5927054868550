import LogoSelsyn from "../assets/LogoSelsynBranca.svg";
import InstaBranco from "../assets/InstaBranco.svg";
import Face from "../assets/FacebookBranco.svg";
import Linkedin from "../assets/LinkedinBranco.svg";
import Whats from "../assets/WPP.svg";
import Email from "../assets/E-mailBranco.svg";
import Google from "../assets/Google.svg";
export default function Footer() {
  return (
    <>
      <footer className=" px-4 justify-center bg-Footer flex  md:justify-around text-white  md:px-0 py-12">
        <div>
          <img className="w-8/12 md:w-8/12" src={LogoSelsyn} alt="" />
          <p className=" text-xs md:text-base">Juntos, vamos em frente!</p>

          <div className="flex  md:-ml-2">
            <a href="https://www.instagram.com/selsyntecnologia/">
              <img
                className="w-10/12  md:w-full hover:scale-110  duration-300"
                src={InstaBranco}
                alt="Instagram"
              />
            </a>
            <a href="https://www.facebook.com/selsyntecnologia/?locale=pt_BR">
              <img
                className="w-10/12  md:w-full hover:scale-110  duration-300"
                src={Face}
                alt="Facebook"
              />
            </a>

            <div className="flex items-center justify-center">
              <a href="https://br.linkedin.com/company/selsyntecnologia">
                <img
                  className="w-14  md:w-16 h-16  hover:scale-110  duration-300"
                  src={Linkedin}
                  alt="Linkedin"
                />
              </a>
            </div>
          </div>

          <div className="mb-10 w-40 md:w-96">
            <span className="text-xs md:text-base">
              R. Nereu Ramos, 748 - sala 210 - Centro, Imbituba - SC, 88780-000
            </span>
          </div>
          <div className="ml-0 md:-ml-3">
            <img src={Google} alt="" />
          </div>
        </div>
        <div className="text-white flex flex-col md:items-left">
          <h1 className="flex justify-center mt-8 text-xl md:mr-32 md:mt-20 mb-10 md:text-2xl font-bold">
            Contato
          </h1>
          <div className="flex flex-col">
            <div className="flex items-center -ml-9 md:ml-0">
              <img className="w-5/12 md:w-32" src={Email} alt="Email" />
              <p className="text-xs md:text-base">contato@selsyn.com.br</p>
            </div>
            <div className="flex items-center -ml-9 md:ml-0">
           
                <img
                  className=" w-5/12 md:5/12 hover:scale-110  duration-300"
                  src={Whats}
                  alt="Fixo"
                />
              <div>
                <p className="text-xs md:text-base font-bold">Telefone Fixo</p>
                <p className="text-xs md:text-base">(48) 3205-2278</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
