import React, { useEffect, useRef, useState } from "react";
import Map from "../assets/Map.svg";
import Passos from "../assets/PassoApasso.svg";
import US43 from "../assets/US$ 43,7 BI.svg";
import US90 from "../assets/Noventa.svg";
import Car from "../assets/mentoriacar.svg";
import LinhaAnimacao from "../assets/linhaAnimacao.svg";
import CarroAnimacao from "../assets/carroAnimacao.svg";
import "./Mentoria.css";

export default function Mentoria() {
  useEffect(() => {
    const carElement = document.getElementById("carroAnimacao");
    carElement.classList.add("car-animation");
  }, []);


  const [showForm, setShowForm] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js";
    script.onload = () => {
      if (window.RDStationForms) {
        const formContainer = document.getElementById('novo-lead-site-18d99087cd5f9a0faba1');
        if (formContainer) {
          new window.RDStationForms('novo-lead-site-18d99087cd5f9a0faba1', null).createForm();
        }
      }
    };
    document.body.appendChild(script);
  }, [showForm]);

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };
  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setShowForm(false);
    }
  };

  useEffect(() => {
    if (showForm) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showForm]);


  return (
    <>
      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div ref={formRef} className=" z-10 rounded-lg shadow-lg">
            <div role="main" id="novo-lead-site-18d99087cd5f9a0faba1"></div>
          </div>
        </div>
      )}

      <div className="backgroundGrandeMent">
        <div className="p-4">
          <div className="z-0 relative flex items-center justify-center md:mt-20 mx-0  ">
            <div className="md:ml-24 w-full flex flex-col justify-center items-center md:items-start p-6">
              <h3 className="font-semibold text-sm md:text-2xl mb-3">
                Como montar e porque montar sua
              </h3>
              <h1 className="text-center mx-2 md:mx-0 text-4xl md:text-start md:text-6xl text-gradient fontBebasNeue">
                CENTRAL DE RASTREAMENTO VEICULAR COM A SELSYN!
              </h1>
              <div className="z-0 w-3/12 flex">
                <img
                  className="z-0 relative"
                  src={LinhaAnimacao}
                  alt="Linha de Animação"
                />
                <img
                  id="carroAnimacao"
                  className="z-10 -ml-3 md:-ml-10"
                  src={CarroAnimacao}
                  alt="Carro de Animação"
                />
              </div>
            </div>
            <div className=" hidden md:flex justify-center w-full">
              <img className="w-4/6" src={Map} alt="MacBook" />
            </div>
          </div>
          <div className=" hidden w-full mt-5 md:mt-10 md:flex items-center justify-center md:mb-12">
            <img src={Passos} alt="Passos" className="w-11/12 md:w-8/12" />
          </div>
          <div>
            <h1 className="pt-16 font-bold text-2xl md:text-5xl text-gradient text-center mb-5 md:mb-16">
              POR QUE MONTAR?
            </h1>
            <div className="w-full flex flex-col  md:flex-row md:flex items-center justify-center md:gap-10">
              <img src={US43} alt="US 43,7 BI" className="w-10/12 md:w-4/12" />
              <img src={US90} alt="US 90" className="w-10/12 md:w-4/12" />
            </div>
            <div className="flex items-center justify-center md:gap-16">
              <div className="w-40 md:w-52">
                <img className="w-full" src={Car} alt="Carro" />
              </div>
              <div className="w-6/12 md:ml-6">
                <h1 className="titlePlataforma font-bold text-lg md:text-2xl mb-4">
                  20% DOS VEÍCULOS
                </h1>
                <p className="font-semibold">
                  Estima-se que aproximadamente&nbsp;
                  <span className="font-bold titlePlataforma">
                    apenas 20% dos veículos no Brasil
                  </span>
                  &nbsp; são equipados com algum tipo de dispositivo de rastreamento
                  ou monitoramento.
                </p>
              </div>
            </div>
            <div className="flex justify-center mt-6 mb-20">

              <button
                onClick={handleButtonClick}
                className="font-bold gradientButton px-8 py-4 rounded-full hover:scale-110  duration-500">
                Quero montar minha central
              </button>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
