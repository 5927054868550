import LogoSelsyn from "../assets/LogoSelsyn.svg";
import IconInsta from "../assets/IconInsta.svg";
import Select from "./Select";
import { Link, useLocation } from "react-router-dom";
import Dropdown from "./Dropdown";

export default function Nav() {
  const location = useLocation();

  const handleAboutUsClick = (e) => {
    if (location.pathname === "/") {
      e.preventDefault();
      const element = document.getElementById("about-us");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <>
      <nav className="flex items-center justify-around bg-white h-20 w-full">
        <div className="flex-shrink-0">
          <Link to="/">
            <img src={LogoSelsyn} alt="Logo" />
          </Link>
        </div>
        <div>
          <ul className="hidden md:flex space-x-5 text-black font-bold text-3x1">
            <li className="flex items-center">
              <Select
                title="Soluções"
                item="Plataforma"
                link="/Plataforma"
                item2="S-Pro"
                link2="/Spro"
                item3="S-Driver"
                link3="/Sdriver"
              />
            </li>
            <li className="flex items-center">
              <Select
                title="Conteúdo"
                item="Mentoria"
                link="/Mentoria"
                item2="Migração"
                link2 = "/Migracao" 
                item3="Integração"
                link3="/Integracao"
              />
            </li>

            <li className="flex items-center">
              <Select
                title="Sobre nós"
                item="Quem somos"
                link="/#about-us" onClick={handleAboutUsClick}
                item2="Trabalhe conosco"    
                link2="/TrabalheConosco"
              />
            </li>
            <li className="flex items-center">
              <Link to="/Contato">Contato</Link>
            </li>
            <div className="flex">
              <li className="flex items-center">
                <a
                  target="_blank"
                  href="https://www.instagram.com/selsyntecnologia/"
                  rel="noreferrer"
                >
                  <img src={IconInsta} alt="Instagram" />
                </a>
              </li>
            </div>
          </ul>
          <div className="z-10 block md:hidden">
            <Dropdown />
          </div>
        </div>
      </nav>
    </>
  );
}
