import React, { useEffect, useRef, useState } from 'react'
import "./Sdriver.css"
import integracoes from "../assets/integracoes.svg"
import maps from "../assets/mapsIntegracoes.svg"
import gestaoRastreio from "../assets/gestaoRastreio.svg"
import hinova from "../assets/hinova.svg"
import mapbox from "../assets/mapbox.svg"
import segware from "../assets/segware.svg"
import webhook from "../assets/webhook.svg"
import alelo from "../assets/alelo.svg"
const Integracao = () => {


  const formRef = useRef(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js";
    script.onload = () => {
      if (window.RDStationForms) {
        const formContainer = document.getElementById('novo-lead-site-18d99087cd5f9a0faba1');
        if (formContainer) {
          new window.RDStationForms('novo-lead-site-18d99087cd5f9a0faba1', null).createForm();
        }
      }
    };
    document.body.appendChild(script);
  }, [showForm]);

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };
  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setShowForm(false);
    }
  };

  useEffect(() => {
    if (showForm) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showForm]);
  return (

    <>
      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div ref={formRef} className=" z-10 rounded-lg shadow-lg">
            <div role="main" id="novo-lead-site-18d99087cd5f9a0faba1"></div>
          </div>
        </div>
      )}

      <div className='backgroundGrandeSdriver'>
        <div className=" mx-0 z-0 my-16  h-5/12 md:z-0 flex justify-center  md:mx-20 md:mt-40 ">
          <div className=" md:ml-24 p-6 w-full">
            <span className='flex justify-center text-base md:justify-normal md:text-xl'>A Selsyn possui</span>
            <h1 className="text-center text-gradient text-4xl md:text-left md:text-6xl fontBebasNeue ">
              SOLUÇÕES INTEGRADAS
            </h1>
            <span className='flex justify-center text-base md:justify-normal md:text-xl'>com as melhores tecnologias do mercado</span>
            <div className="flex justify-center md:justify-start">
              <button
                onClick={handleButtonClick}
                className="text-gradientButton md:text-gradientButton font-bold mt-6 px-8 py-4 rounded-full hover:scale-110 duration-500"
              >
                QUERO FAZER PARTE
              </button>
            </div>
          </div>
          <div className="hidden md:flex -mt-16 w-full ">
            <img className="w-4/6" src={integracoes} alt="MacBook" />
          </div>
        </div>

        <div className='mx-10 md:ml-48 my-16 justify-center flex items-center gap-6'>
          <div className='w-5/12 md:w-1/12'>
            <img className='w-full' src={maps} alt="" />
          </div>
          <div className='w-full'>
            <h4 className='font-bold text-gradient text-left text-xl md:text-3xl'>Google Maps</h4>
            <span className='text-sm'>Camadas de mapas e endereços</span>
          </div>
        </div>

        <div className='mx-10 md:ml-48 my-16 justify-center flex items-center gap-6'>
          <div className='w-5/12 md:w-1/12'>
            <img className='w-full' src={gestaoRastreio} alt="" />
          </div>
          <div className='w-full'>
            <a href="https://gestaorastreio.com.br/central-de-rastreamento/" target="_blank"><h4 className='font-bold text-gradient text-left text-xl md:text-3xl'>Gestão rastreio</h4></a>
            <span className='text-sm'>Gestão financeira</span>
          </div>
        </div>

        <div className='mx-10 md:ml-48 my-16 justify-center flex items-center gap-6'>
          <div className='w-5/12 md:w-1/12'>
            <img className='w-full' src={hinova} alt="" />
          </div>
          <div className='w-full'>
            <h4 className='font-bold text-gradient text-left text-xl md:text-3xl'>Hinova - <a href="https://hinova.com.br/hinova-solucoes-digitais/sga/" target='_blank'>SGA</a>  e <a href="https://hinova.com.br/hinova-solucoes-digitais/sgr/" target="_blank" rel="noopener noreferrer">SGR</a></h4>
            <span className='text-sm'>Gerenciamento administrativo</span>
          </div>
        </div>

        <div className='mx-10 md:ml-48 my-16 justify-center flex items-center gap-6'>
          <div className='w-5/12 md:w-1/12'>
            <img className='w-full' src={mapbox} alt="" />
          </div>
          <div className='w-full'>
            <a href="http://www.mapbox.com" target="_blank" rel="noopener noreferrer"> <h4 className=' font-bold text-gradient text-left text-xl md:text-3xl'>Mapbox</h4></a>
            <span className='text-sm'>Tecnologia de localização</span>
          </div>
        </div>

        <div className='mx-10 md:ml-48 my-16 justify-center flex items-center gap-6'>
          <div className='w-5/12 md:w-1/12'>
            <img className='w-full' src={segware} alt="" />
          </div>
          <div className='w-full'>
            <a href="https://segware.com/integradores/" target="_blank" rel="noopener noreferrer"><h4 className='font-bold text-gradient text-left text-xl md:text-3xl'>Segware</h4></a>
            <span className='text-sm'>Sistema Integrado para gestão e monitoramento de alertas</span>
          </div>
        </div>

        <div className='mx-10 md:ml-48 my-16 justify-center flex items-center gap-6'>
          <div className='w-5/12 md:w-1/12'>
            <img className='w-full' src={webhook} alt="" />
          </div>
          <div className='w-full'>
            <h4 className='font-bold text-gradient text-left text-xl md:text-3xl'>Webhook</h4>
            <span className='text-sm'>Comunicação leve orientada por eventos que envia dados automaticamente entre aplicativos via HTTP</span>
          </div>
        </div>

        <div className='mx-10 md:ml-48 my-16 justify-center flex items-center gap-6'>
          <div className='w-5/12 md:w-1/12'>
            <img className='w-full' src={alelo} alt="" />
          </div>
          <div className='w-full'>
           <a href="https://www.alelo.com.br" target="_blank" rel="noopener noreferrer"><h4 className='font-bold text-gradient text-left text-xl md:text-3xl'>Alelo</h4></a> 
            <span className='text-sm'>Cartão frotas</span>
          </div>
        </div>


      </div>
    </>
  )
}

export default Integracao