import React, { useEffect, useRef, useState } from "react";
import MacBookReto from "../assets/macBookReto.svg";
import iconPlat from "../assets/icon-Plat.svg";
import iconOne from "../assets/iconOnePlat.svg";
import iconTwo from "../assets/iconTwoPlat.svg";
import iconTree from "../assets/IconTreePlat.svg";
import Funcionalidade from "../assets/Funcionalidades.svg";
import FuncionalidadeResp from "../assets/funcResponsivo.svg";
import dashBoards from "../assets/dashS.svg";

import "./Plataforma.css";

export default function Plataforma() {



  const [showForm, setShowForm] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js";
    script.onload = () => {
      if (window.RDStationForms) {
        const formContainer = document.getElementById('novo-lead-site-18d99087cd5f9a0faba1');
        if (formContainer) {
          new window.RDStationForms('novo-lead-site-18d99087cd5f9a0faba1', null).createForm();
        }
      }
    };
    document.body.appendChild(script);
  }, [showForm]);

  const handleButtonClick = () => {
    setShowForm(!showForm);
  };
  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setShowForm(false);
    }
  };

  useEffect(() => {
    if (showForm) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showForm]);



  return (
    <>

      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div ref={formRef} className=" z-10 rounded-lg shadow-lg">
            <div role="main" id="novo-lead-site-18d99087cd5f9a0faba1"></div>
          </div>
        </div>
      )}


      <div className="backGroundSolucoes1 flex items-center  justify-center mb-5 md:mb-0 ">
        <div className="z-0 flex items-center  justify-around  mt-10 mx-20">
          <div className="md:ml-20 w-full  ">
            <h3 className="font-medium text-center md:text-start text-sm md:text-xl mb-2 md:mb-3">
              A plataforma ideal para sua empresa:
            </h3>
            <h1 className="text-4xl text-center md:text-start md:text-6xl titlePlataforma fontBebasNeue ">
            OFERECEMOS TECNOLOGIA E SEGURANÇA PARA VOCÊ E SEU CLIENTE
            </h1>
            <div className="w-full flex justify-center md:flex md:justify-start mt-4">
              <img className=" w-40" src={iconPlat} alt="" />
            </div>
          </div>
          <div className=" hidden md:flex justify-center w-full">
            <img className="w-5/6" src={MacBookReto} alt="MacBook" />
          </div>
        </div>
      </div>

      <div className="z-0 bg-Plat flex flex-col md:flex-row justify-center items-center gap-3 md:gap-20">
        <div className="my-10 md:my-20 bg-white p-2 md:p-10 h-56 md:h-72 w-64 flex flex-col justify-center items-center gap-5 rounded-xl">
          <img className="mt-2 w-4 md:w-8" src={iconOne} alt="" />
          <div className="bg-blue-600  h-00 rounded-lg w-20"> </div>
          <div className="titlePlataforma font-bold  text-lg md:text-2xl text-center tracking-tighter">
            <p>RELÁTORIOS</p>
            <span>COMPLETOS</span>
          </div>
        </div>
        <div className="my-10 md:my-20 bg-white p-2  md:p-10 h-56 md:h-72 w-64 flex flex-col justify-center items-center gap-5 rounded-xl">
          <img className="mt-2 w-5 md:w-10" src={iconTwo} alt="" />
          <div className="bg-blue-600  h-00 rounded-lg w-20 "> </div>
          <div className="titlePlataforma font-bold  text-lg md:text-2xl text-center tracking-tighter">
            <p>DASHBOARD</p>
            <span>MODERNO</span>
          </div>
        </div>
        <div className="my-10 md:my-20 bg-white p-2 md:p-10 h-56 md:h-72 w-64 flex flex-col justify-center items-center gap-5 rounded-xl">
          <img className="mt-2 w-6 md:w-12" src={iconTree} alt="" />
          <div className="bg-blue-600  h-00 rounded-lg w-20"> </div>
          <div className="titlePlataforma font-bold  text-lg md:text-2xl text-center w150 tracking-tighter">
            <p>CONTROLE DOS</p>
            <span>DISPOSITIVOS</span>
          </div>
        </div>
      </div>
      <div className="backgroundGrandePlat">
        <div className="p-4">
          <h1 className="titlePlataforma font-bold text-2xl mx-3 md:mx-0 md:textPerso tracking-tighter text-center mt-10 md:mt-20">
            O DASHBOARD PERFEITO PARA SUA GESTÃO
          </h1>
          <div className="w-full my-2 flex justify-center md:hidden">
            <img className="w-10/12" src={FuncionalidadeResp} alt="" />
          </div>
          <div className="hidden w-full md:flex items-center justify-center mt-10">
            <img className="w-10/12" src={Funcionalidade} alt="" />
          </div>
          <div className="itens-center justify-center flex my-10 md:mt-32">
              <button 
              onClick={handleButtonClick}  
              className="text-gradientButton font-bold px-8 py-4 rounded-full hover:scale-110  duration-500">
                Fale com um especialista
              </button>
          
          </div>
        </div>
        <div className="w-full hidden md:flex items-center justify-center my-12">
          <img src={dashBoards} className="w-9/12" alt="" />
        </div>
      </div>
    </>
  );
}
